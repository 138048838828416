import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/gDE0bLfmAQk">
    <SEO title="But Jesus - Hebrews" />
  </Layout>
)

export default SermonPost
